<template>
  <v-menu
    v-if="translation"
    v-model="basketShow"
    :close-on-content-click="false"
    offset-x
    origin="top right"
    nudge-left="300"
    min-width="300"
    transition="scale-transition"
    content-class="basket basket--header"
    z-index="10"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :class="[
          'd-flex',
          disabled ? 'jigsaw-disabled' : ''
        ]"
        :content="getBacketCount()"
        :value="getBacketCount()"
        color="secondary"
        overlap
        left
      >
        <v-btn
          class="d-sm-flex d-none"
          icon
          large
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          @click="checkBasketSize()"
        >
          <icons :icon-name="getBasketIcon()" />
        </v-btn>

        <v-btn
          class="d-sm-none d-flex"
          icon
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          @click="checkBasketSize()"
        >
          <icons :icon-name="getBasketIcon()" />
        </v-btn>
      </v-badge>
    </template>
    <v-card class="jigsaw-card">
      <v-card-title class="align-center pa-2 theme5 line-height-1">
        <icons
          right
          icon-name="basket"
        />
        <span
          class="jigsaw-theme1 jigsaw-text-script jigsaw-text-h1 pl-2 mb-n1"
          v-text="translation.all_pages.basket.basket_label"
        />
        <v-spacer />
        <v-btn
          class="basket-close"
          icon
          @click="closeBasket()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <div v-if="basket.length > 0">
        <div class="basket-container">
          <div
            v-if="showScrollPrompts"
            :class="[
              'basket-scroll__prompt text-center top-prompt',
              showHeader ? 'is-active' : ''
            ]"
            @click="scrollTopBasket()"
          >
            <v-icon
              color="theme5"
              v-text="'mdi-chevron-up'"
            />
          </div>

          <div
            ref="basketScroller"
            class="basket-scroller"
            @scroll.passive="onScroll"
          >
            <v-card-text
              ref="basketlist"
              class="basket-list jigsaw-card-text jigsaw-theme1 pa-2 pb-0"
            >
              <v-row
                v-for="(item, i) in basket"
                :key="i"
                no-gutters
                class="basket-lined"
              >
                <v-col cols="12">
                  <span
                    class="jigsaw-lead-text font-weight-medium"
                    v-text="item.name"
                  />
                  <small
                    v-if="item.attribute_title !== 'Single' && item.product_type !== 'booking'"
                    class="jigsaw-body-text pl-1"
                    v-text="'(' + item.label + ')'"
                  />
                </v-col>
                <v-col cols="9">
                  <v-row
                    no-gutters
                    class="pb-2"
                  >
                    <v-col
                      cols="4"
                      class="pr-1"
                    >
                      <div
                        v-if="item.url !== ''"
                        class="pointer"
                        @click="goToLink(settings.store_url + item.url)"
                      >
                        <BasketImage
                          :item="item"
                          path="/images/products/thumbnail/"
                        />
                      </div>
                      <BasketImage
                        v-else
                        :item="item"
                        path="/images/products/thumbnail/"
                      />
                    </v-col>
                    <v-col
                      cols="8"
                    >
                      <div
                        v-if="item.attribute_title !== 'Single'"
                        class="jigsaw-body-text d-flex flex-column height-100"
                      >
                        <span
                          v-if="item.product_type === 'voucher'"
                          v-text="item.category_title"
                        />
                        <span
                          v-if="item.product_type === 'booking'"
                          v-text="item.label + ' ' + item.group_title + parseDate(item.booking_date, ', MMMM Do YYYY')"
                        />
                        <span
                          v-if="item.product_type === 'product'"
                          v-text="item.group_type + ' - ' + item.group_title"
                        />
                        <span
                          v-if="item.product_type === 'subscription'"
                          v-text="item.group_type + ' - ' + item.group_title"
                        />
                        <span
                          v-if="item.product_type === 'subscription'"
                          class="font-weight-medium mt-auto"
                          v-text="'(Subscription)'"
                        />
                        <v-btn
                          text
                          x-small
                          color="error"
                          class="mt-auto mr-auto mb-n1 ml-n1"
                          @click="removeFromBasket(item)"
                        >
                          <v-icon
                            small
                            v-text="'mdi-trash-can-outline'"
                          />
                          <span
                            class="jigsaw-body-text"
                            v-text="translation.all_pages.basket.basket_remove_label"
                          />
                        </v-btn>
                      </div>
                      <div
                        v-else
                        class="jigsaw-body-text d-flex flex-column height-100"
                      >
                        <v-btn
                          text
                          x-small
                          color="error"
                          class="mt-auto mr-auto mb-n1 ml-n1"
                          @click="removeFromBasket(item)"
                        >
                          <v-icon
                            small
                            v-text="'mdi-trash-can-outline'"
                          />
                          <span
                            class="jigsaw-body-text"
                            v-text="translation.all_pages.basket.basket_remove_label"
                          />
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="3"
                  class="d-flex flex-column justify-end text-right"
                >
                  <div
                    class="mr-1"
                    v-text="'£' + item.price"
                  />
                  <div class="d-flex align-center justify-end mr-n1">
                    <v-btn
                      icon
                      small
                      :disabled="item.quantity === '1'"
                      @click="removeItem(item)"
                    >
                      <v-icon
                        small
                        v-text="'fas fa-minus-square'"
                      />
                    </v-btn>
                    <div v-text="item.quantity" />
                    <v-btn
                      icon
                      small
                      @click="addItem(item)"
                    >
                      <v-icon
                        small
                        v-text="'fas fa-plus-square'"
                      />
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div
                    v-if="item.free_post_exclude === '1'"
                    class="jigsaw-body-text font-weight-medium"
                    v-text="translation.general.labels.free_post_not_eligible_text"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </div>
          <div
            v-if="showScrollPrompts"
            :class="[
              'basket-scroll__prompt text-center bottom-prompt',
              showFooter ? 'is-active' : ''
            ]"
            @click="scrollBottomBasket()"
          >
            <v-icon
              color="theme5"
              v-text="'mdi-chevron-down'"
            />
          </div>
        </div>

        <v-divider class="mx-2 pa-0" />

        <v-card-text class="jigsaw-card-text jigsaw-theme1 pa-2 pt-0">
          <v-row
            no-gutters
            class="jigsaw-lead-text py-1"
          >
            <v-col
              cols="9"
              v-text="translation.all_pages.basket.basket_subtotal_label"
            />
            <v-col
              cols="3"
              class="text-right"
            >
              <div
                class="mr-1"
                v-text="'£' + productTotal"
              />
            </v-col>
          </v-row>

          <v-row
            no-gutters
          >
            <v-col
              cols="9"
            >
              <span v-text="translation.all_pages.basket.basket_total + ' '" />
              <small v-text="'(' + translation.all_pages.basket.basket_subtotal_notice + ')'" />
            </v-col>
            <v-col
              cols="3"
              class="text-right"
            >
              <div
                class="mr-1"
                v-text="'£' + total"
              />
            </v-col>
            <v-col v-if="showFreeHint">
              <v-divider class="mx-0 pa-0 mt-1 pb-1" />
              <div class="d-flex jigsaw-body-text justify-center align-center">
                <span v-html="translation.all_pages.basket.basket_free_post_prefix" />
                <span
                  class="jigsaw-theme1 jigsaw-text-script is-narrow jigsaw-button-text px-1 font-weight-bold"
                  v-text="'£' + freePostTotal"
                />
                <span v-text="translation.all_pages.basket.basket_free_post_suffix" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />
        <v-card-actions class="theme5 px-0 py-3">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="pt-0 px-3 pb-3"
            >
              <v-btn
                color="info"
                block
                depressed
                :to="{ path: settings.basket_url }"
                @click="basketShow = false"
                v-text="translation.all_pages.basket.basket_view_basket_button"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0 px-3"
            >
              <v-btn
                color="primary"
                block
                depressed
                :to="{ path: settings.checkout_url }"
                @click="basketShow = false"
                v-text="translation.all_pages.basket.basket_checkout_button"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </div>
      <v-card-text
        v-else
        class="d-flex flex-column jigsaw-card-text jigsaw-theme1 text-center"
      >
        <span v-text="translation.all_pages.basket.empty_basket_text" />
        <div
          class="jigsaw-card-title-text pa-4 rotate-90"
          v-text="':('"
        />

        <v-icon v-text="'mdi-coffee-off-outline'" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { forEach } from 'lodash'
import general from '@/utils/general/general'
import basketFn from '@/utils/basket/basketFn'
import icons from '@/components/icons/icons'
import BasketImage from './basketImage'

export default {
  name: 'BasketDrawer',
  components: {
    icons,
    BasketImage
  },
  mixins: [general, basketFn],
  data: () => ({
    //
    settings: {},
    //
    basketShow: false,
    basket: [],
    discount: {},
    //
    disabled: false,
    //
    updating: false,
    //
    productTotal: '0.00',
    subtotal: '0.00',
    total: '0.00',
    //
    showScrollPrompts: false,
    showFooter: false,
    showHeader: false,
    //
    freePostTotal: '0.00',
    free_delivery_min: '0.00',
    showFreeHint: false
  }),
  watch: {
    $route () {
      this.checkRoute()
    }
  },
  computed: {
    translation () {
      return this.$store.getters.getTranslation
    }
  },
  //
  created () {
    // Settings
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.settings = result
        this.checkRoute()
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getSiteSettings, (result) => {
        this.free_delivery_min = result.free_delivery_min
      }, {
        immediate: true
      }
    )

    // Basket
    this.$store.watch(
      (state, getters) => getters.getBasket, (result) => {
        if (result) {
          this.processBasket(result)
        }
      }, {
        immediate: true
      }
    )
  },
  //
  mounted () {
    this.checkRoute()
  },
  //
  methods: {
    checkRoute () {
      this.disabled = this.settings.checkout_url === this.$route.path
    },
    getBasketIcon () {
      const size = this.basket.length
      if (size > 4) {
        return 'basket-4'
      } else if (size > 0) {
        return 'basket-' + size
      }
      return 'basket'
    },
    openBasket () {
      this.basketShow = true
    },
    closeBasket () {
      this.basketShow = false
    },
    //
    processBasket (resource) {
      if (resource) {
        let productTotal = 0
        let subtotal = 0
        let freeTotal = 0
        let freePostTotal = 0
        const basket = []

        /**
         * Basket Products
         */
        if (resource.list && resource.list.length > 0) {
          forEach(resource.list, (item) => {
            if (item.product_type === 'booking' && item.parent_name !== '') {
              item.name = item.parent_name
            }
            item.url = item.cat_url + (item.listing === '1' ? item.child_url : item.parent_url)
            item.media = this.getArrayContent(item.media)
            item.media_default = this.getArrayContent(item.media_default)
            item.images = item.media.concat(this.getArrayContent(item.media_default))
            productTotal = productTotal + (parseFloat(item.price) * parseInt(item.quantity))
            // free post checker
            if (item.free_post_exclude !== '1') {
              freeTotal = freeTotal + (parseFloat(item.price) * parseInt(item.quantity))
            }
          })
        }

        subtotal = productTotal

        // free_delivery_min
        if (parseFloat(this.free_delivery_min) > parseFloat(freeTotal)) {
          freePostTotal = parseFloat(this.free_delivery_min) - parseFloat(freeTotal)
          this.freePostTotal = freePostTotal.toFixed(2)
          this.showFreeHint = true
        } else {
          this.showFreeHint = false
        }
        /**
         * Discount
         */
        // if (resource.discount && resource.discount.amount) {}

        this.productTotal = productTotal.toFixed(2)
        this.subtotal = subtotal.toFixed(2)
        this.total = subtotal.toFixed(2)

        if (resource.list && resource.list.length > 0) {
          basket.push(...resource.list)
        }

        this.basket = basket

        setTimeout(() => {
          this.checkBasketSize()
        }, 1)
      }
    },
    //
    // Scroll
    //
    onScroll () {
      const basketScroller = this.$refs.basketScroller
      const basketlist = this.$refs.basketlist
      if (basketScroller && basketlist) {
        this.showHeader = basketScroller.scrollTop > 30
        this.showFooter = ((basketlist.clientHeight - basketScroller.clientHeight) - basketScroller.scrollTop) > 30
      }
    },
    scrollTopBasket () {
      this.scrollBasket(0)
    },
    scrollBottomBasket () {
      this.scrollBasket(this.$refs.basketlist.clientHeight - this.$refs.basketScroller.clientHeight)
    },
    scrollBasket (pos) {
      if (pos > -1) {
        const basketScroller = this.$refs.basketScroller
        basketScroller.scroll({
          top: pos,
          behavior: 'smooth'
        })
      }
    },
    checkBasketSize () {
      setTimeout(() => {
        const basket = this.$refs.basketScroller
        const container = this.$refs.basketlist
        if (basket && container) {
          this.showScrollPrompts = container.clientHeight > basket.clientHeight

          this.onScroll()
        }

        return false
      }, 300)
    },
    //
    getBacketCount() {
      let count = 0
      this.basket.forEach(item => {
        count = count + parseInt(item.quantity)
      })

      return count
    },
    //
    goToLink (path) {
      if (path && this.$route.path !== path) {
        this.closeBasket()
        this.$router.push({ path: path })
      }
    }
  }
}
</script>
